<template>
  <div class="layout-box">
    <el-dialog
      title="logout tip"
      :show-close="false"
      :visible.sync="logoutVisible"
      width="30%">
      <span>Your account has been logged in in another place.</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="relogin">OK</el-button>
      </span>
    </el-dialog>
    <div class="title-bar">
      <div class="search-box" @click="backHome">
        <img style="width: 36px; height: 36px; margin: 0 10px;" src="/logo.png" alt="">
        <span>Textcome</span>
      </div>
      <div style="flex: 1;"></div>
      <div class="right">
        <div @click="addPhone" class="bi-icon-box">
          <el-tooltip class="item" :open-delay="300" effect="dark" content="Get a new phone number" placement="left">
            <i class="bi bi-telephone-plus"></i>
          </el-tooltip>
        </div>
        <div @click="logout" class="bi-icon-box">
          <el-tooltip class="item" :open-delay="300" effect="dark" content="logout" placement="left">
            <i class="el-icon-switch-button"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="nav-bar">
        <div class="icon-wrapper">
          <div @click="linkTo('message')" :class="`icon-box ${path==='message' || path==='index'?'focus':''}`">
            <el-badge is-dot class="unread-message" v-if="unread" :hidden="unread<=0">
              <i class="bi bi-phone-fill"></i>
            </el-badge>
            <i class="bi bi-voicemail" v-else></i>
          </div>
          <div class="border"></div>
          <div @click="linkTo('import')" :class="`icon-box ${path==='import'?'focus':''}`">
            <i class="bi bi-file-arrow-up"></i>
          </div>
          <div @click="linkTo('icon')" :class="`icon-box ${path==='icon'?'focus':''}`">
            <i class="bi bi-award"></i>
          </div>
          <div @click="linkTo('question')" :class="`icon-box ${path==='question'?'focus':''}`">
            <i class="bi bi-question-diamond"></i>
          </div>
        </div>
      </div>
      <div class="list-view">
        <router-view></router-view>
      </div>
      <div class="content-center">
        <div class="send-info single-input" v-if="selectContact">
          <p class="send-phone">{{ selectContact.phone }}</p>
          <i class="bi bi-telephone"></i>
        </div>
        <div :class="`send-info ${mutilPhones.length?'mutil-input':'single-input'}`" v-else>
          <div class="to-title">
            <div class="to-text">To</div>
          </div>
          <div class="numbers">
            <div class="mutil-phone-item" v-for="(item, index) in mutilPhones" :key="index">
              <span class="mutil-phone">{{ item }}</span>
              <i @click="removeMutilPhone(item)" class="el-icon-close"></i>
            </div>
            <input ref="numberInput" type="text" @input="inputChange" @change="phoneChange" v-model="inputPhone" placeholder="Type a phone...">
          </div>
          <div v-if="mutilPhones.length" @click.stop="clearAll" class="mutil-close">
            <i class="el-icon-circle-close"></i>
          </div>
        </div>
        <chat-room/>
      </div>
      <div class="option-right">
        <phone />
      </div>
    </div>
    <el-dialog
      title="Tip"
      :visible.sync="dialogTip"
      width="30%">
      <span>The message prompt tone is played automatically</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="confirmTip">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Phone from '@/components/Phone'
import ChatRoom from './chatroom/index.vue'
import { insertMessage, addUnreadMessage, loadUnreadMessage, setContacts, updateLastMessage, selectContact, loadLastMessages } from '@/utils/indexdb.js'

import { stringToColor } from '@/utils/color'
import { mapGetters } from 'vuex'
import { initWS } from './ws'
import { sortHandler } from '@/utils/sort'

export default {
  components: {
    Phone,
    ChatRoom
  },
  data() {
    return {
      path: 'index',
      dialogTip: false,
      newMessage: false,
      logoutVisible: false,
      inputPhone: '',
      playing: false,
      numbers: [],
      ws: null,
      heartCheck: null,
      autoLogin: null
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'phones',
      'password',
      'mutilPhones',
      'contacts',
      'selectContact', 
      'fromPhone',
      'unreadMessage'
    ]),
    unread: function() {
      const unreadMessages = loadUnreadMessage()
      for (let i = 0; i < this.phones.length; i++) {
        const { fullNumber } = this.phones[i]
        for (let j = 0; j < unreadMessages.length; j++) {
          const message = unreadMessages[j]
          if (fullNumber === message.to) {
            return 1
          }
        }
      }
      return 0
    },
    bgColor: function() {
      return stringToColor(this.name)
    }
  },
  watch: {
    selectContact: function(val) {
      if (val !== null) {
        this.inputPhone = val.phone
      }
      this.inputPhone = ''
      this.$nextTick(() => {
        this.$refs.numberInput && this.$refs.numberInput.focus()
      })
    }
  },
  async created() {
    const pathname = location.pathname
    if (pathname === '/import') {
      this.path = 'import'
    } else if (pathname === '/icon') {
      this.path = 'icon'
    } else {
      this.path = 'index'
    }
    this._initConnect()
    this._autoLogin()
  },
  methods: {
    _initConnect(callback) {
      try {
        const socket = initWS({
          name: this.name,
          open: this._open,
          close: this._close,
          message: this._message,
          error: this._error
        })
        this.ws = socket
        callback && callback()
      } catch (e) {
        console.error(e)
      }
    },
    confirmTip() {
      this.dialogTip = false
    },
    survey() {
      const href = process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://advice.textcome.net'
      const url = `${href}?username=${this.name}`
      window.open(url)
    },
    backHome() {
      this.$router.push('/')
    },
    clearAll() {
      this.$store.dispatch('phone/setMutilPhones', [])
    },
    relogin() {
      this.logoutVisible = false
      this.$router.push('/login')
    },
    _autoLogin() {
      this.autoLogin = setInterval(() => {
        const info = {
          username: this.name, 
          password: this.password
        }
        this.$store.dispatch('user/login',info).then(() => {
          localStorage.setItem('login_info', JSON.stringify(info))
        }).catch((msg) => {
          this.$message.error(msg)
          this.$router.push('/login')
        })
      }, 1000*60*25)
    },
    _error(error) {
      console.error(error)
      this.$message.error('Chat room connect error, please check network or refresh page')
    },
    _message(event) {
      const data = event.data
      if (!data) {
        console.warn('unknown message: ', event)
        return
      }
      if (data.length === 'pong'.length && 'pong' === data) {
        return
      } else {
        const msg = JSON.parse(data)
        if (Array.isArray(msg)) {
          this._handlerMutilMsgs(msg)
        } else if (msg instanceof Object) {
          this._handlerSingleMsg(msg)
        }
      }
    },
    _handlerMutilMsgs(remoteMessages) {
      // const unreadMessages = loadUnreadMessage()
      // const items = [...unreadMessages, ...remoteMessages]
      // updateLastMessages(items)
      // this.$store.dispatch('sms/SetUnreadMessage', items)
      for (let i = 0; i < remoteMessages.length; i++) {
        const message = remoteMessages[i]
        this._handlerSingleMsg(message)
      }
    },
    _handlerSingleMsg(msg) {
      const {from, to, body, time, messageId} = msg
      const item = {
        from,
        time,
        to,
        messageId,
        body,
        color: stringToColor(to),
        isLeft: true,
        ws: true
      }
      updateLastMessage(msg)
      
      if (this.fromPhone && this.fromPhone.fullNumber === from) {
        if (this.selectContact && this.selectContact.phone === to) {
          this.$store.dispatch('sms/PushMessage', item)
          insertMessage(from, to, item)
          this.$nextTick(() => {
            const domWrapper = document.querySelector('.send-content')
            const { scrollHeight } = domWrapper
            domWrapper.scrollTop = scrollHeight + domWrapper.clientHeight
          })
          this._syncLastMessage(from)
          return
        }
      }
      // 查找当前联系人
      const newContacts = selectContact(from)
      let newContact = true
      for (let i = 0; i < newContacts.length; i++) {
        const element = newContacts[i];
        if (element.phone === to) {
          newContact = false
          break
        }
      }
      if (newContact) {
        // 在线状态添加新的陌生联系人
        newContacts.push({
          phone: to,
          receive: 1,
          color: stringToColor(to)
        })
      }
      addUnreadMessage(item)
      this.$store.dispatch('sms/AddUnreadMessage', item)
      if (this.fromPhone && this.fromPhone.fullNumber === from) {
        this.$store.dispatch('sms/SetContact', newContacts)
        this._syncLastMessage(from)
      }
      setContacts(from, newContacts)
    },
    _syncLastMessage(from) {
      const lastMessages = loadLastMessages(from)
      const sortArr = lastMessages.sort(sortHandler('timestamp'))
      this.$store.dispatch('sms/SetLastMessages', sortArr)
    },
    _open() {
      console.log("[open] Connection established");
      this.heartCheck = setInterval(() => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send("ping")
        }
      }, 30000)
      setTimeout(() => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send("pull")
        }
      }, 1000)
    },
    _close(event) {
      this.heartCheck && clearInterval(this.heartCheck)
      console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason} wasClean=${event.wasClean}`);
      if (event.code === 1012 && event.reason === 'reset') {
        this.$store.dispatch('user/logout')
        this.$message.warning('Remote login!')
        setTimeout(() => {
          this.logoutVisible = true
        }, 500)
      } else if (event.code === 1006) {
        setTimeout(() => {
          try {
            this._initConnect(() => {
              this.$message.success('Network shake, reconnect success')
            })
          } catch(e) {
            this.$message.error('Reconnect failed.')
            console.error(e)
          }
        }, 10000);
      }
    },
    phoneChange() {
      if (this.mutilPhones.length > 0) {
        if (this.inputPhone) {
          this.mutilPhones.push(this.inputPhone)
          this.inputPhone = ''
        }
        return
      }
      let number = this.inputPhone
      if (number.length === 10) {
        number = '1' + number
      }
      this.$store.dispatch('sms/UpdateSelectContact', {
        phone: number
      })
    },
    inputChange() {
      if (this.inputPhone.includes(' ')) {
        let errorCount = 0
        const numbers = this.inputPhone.split(' ')
        if (numbers.length > 100) {
          this.$message.warning('Numbers sent in batches cannot exceed 100')
          return
        }
        for (let i = 0; i < numbers.length; i++) {
          let number = numbers[i]
          if (number !== '' && !this.mutilPhones.includes(number)) {
            if (number.length === 10) {
              number = '1' + number
            }
            if (number.length !== 11) {
              errorCount++
              continue
            }
            this.mutilPhones.push(number)
          }
        }
        errorCount > 0 && this.$message.warning('input invalid ' + errorCount + ' number.')
        this.inputPhone = ''
      }
    },
    removeMutilPhone(phone) {
      this.$store.dispatch('phone/removeMutilPhones', phone)
    },
    linkTo(path) {
      this.path = path
      this.$router.push('/' + path).catch(()=>{})
    },
    addPhone() {
      this.$alert(`Do you want to get a new number?`, 'Tip', {
        confirmButtonText: 'Yes',
        callback: (e) => {
          if (e === 'cancel') {
            return
          }
          this.$store.dispatch('phone/getNewPhone').catch(e => {
            this.$message.error(e)
          })
        }
      })
    },
    logout() {
      this.$store.dispatch('phone/cleanUp')
      this.$store.dispatch('sms/cleanUp')
      this.$store.dispatch('user/cleanUp')
      this.$store.dispatch('account/cleanUp')
      if (this.ws) {
        this.ws.close(1000, "Work complete")
      }
      if (this.heartCheck) {
        clearInterval(this.heartCheck)
      }
      if (this.autoLogin) {
        clearInterval(this.autoLogin)
      }
      this.$store.dispatch('user/logout')
      this.$router.push('/')
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">

.layout-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  .el-badge__content.is-fixed {
    top: 6px;
    right: 18px;
  }
  .el-badge__content.is-fixed.is-dot {
    top: 6px;
    right: 12px;
  }
  .bi {
    cursor: pointer;
  }
  .main-content {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  .list-view {
    width: 360px;
    border-right: 1px solid rgba(0,0,0,.12);
    position: relative;
    overflow: hidden;
  }
  .content-center {
    flex: 1;
    display: flex;
    flex-direction: column;
    .single-input {
      height: 64px;
      .to-padding {
        height: 0;
      }
    }
    .mutil-input {
      max-height: 100px;
      overflow: hidden;
      padding: 12px 0 13px 0;
      .to-title {
        .to-padding {
          height: 0px;
        }
      }
      .mutil-phone-item {
        padding-right: 4px;
        padding-left: 12px;
        border-radius: 13px;
        font-size: 10px;
        background-color: #fff;
        cursor: pointer;
        margin: 4px;
        box-shadow: 0 0 0 1px #e8eaed inset;
        .el-icon-close {
          margin: 0 2px;
        }
      }
      .numbers {
        display: flex;
        flex-flow: wrap;
        flex: 1;
        align-items: center;
        overflow-y: scroll;
        height: 100%;
      }
      .mutil-close {
        cursor: pointer;
        padding: 6px 10px;
        color: rgb(119, 118, 118);
      }
      .mutil-close:hover {
        color: rgb(81, 81, 81);
      }
      .mutil-close:active {
        color: rgb(119, 118, 118) !important;
      }
      .mutil-phone-item:hover {
        background-color: #f8f9f8;
      }
      input {
        margin: 4px;
      }
    }
    .send-info {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font: 400 16px/24px Roboto,"Helvetica Neue",sans-serif;
      letter-spacing: .1px;
      border-bottom: 1px solid rgba(0,0,0,.12);
      box-sizing: border-box;
      .bi-telephone {
        padding: 10px 15px;
      }
      input {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        order: 2;
        display: block;
        background: none;
        padding: 2px 2px 1px;
        line-height: 26px;
        height: 30px;
        -ms-flex-preferred-size: 26px;
        border-radius: 0;
        border: none;
        outline: none;
        width: 160px;
        box-sizing: border-box;
        float: left;
      }
      .number-box {
        div {
          padding-top: 2px;
          padding-bottom: 2px;
          padding-right: 2px;
          padding-left: 12px;
          border-radius: 13px;
          background-color: #fff;
          font-weight: 500;
          color: #5f6368;
          box-shadow: 0 0 0 1px #e8eaed inset;
          display: inline-block;
        }
      }
      .labels {
        span {
          padding-top: 8px;
          padding-bottom: 8px;
          padding-right: 12px;
          padding-left: 12px;
          border-radius: 16px;
          background-color: #fff;
          color: #5f6368;
          box-shadow: 0 0 0 1px #e8eaed inset;
          font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
          letter-spacing: 0.2px;
          display: inline;
          font-weight: 500;
          margin: 4px;
          cursor: default;
        }
        span:hover {
          background-color: #ecedee;
        }
      }
      .to-title {
        font: 400 14px/20px Roboto,"Helvetica Neue",sans-serif;
        letter-spacing: .2px;
        color: #202124;
        min-height: unset;
        width: 48px;
      }
      .send-phone {
        flex: 1;
        overflow: hidden;
        text-align: left;
        color: #202124;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 20px;
      }
      i.el-icon-circle-close {
        padding: 0;
        min-width: 0;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        line-height: 40px;
        border-radius: 50%;
        color: #5f6368;
      }
    }
  }
  .title-bar {
    height: 64px;
    text-align: left;
    display: flex;
    padding: 8px;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.12);
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      .bi-telephone-plus, .bi-hand-index-thumb {
        font-size: 16px;
        line-height: 40px;
      }
      .bi-icon-box {
        padding: 4px;
        border-radius: 50%;
        padding: 0;
        min-width: 0;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
      }
      .user-avator-box {
        padding: 4px;
        border-radius: 50%;
        .user-avator {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          color: #FFF;
          font-weight: bold;
          display: inline-block;
          font-size: 18px;
          cursor: pointer;
          text-align: center;
          line-height: 32px;
          box-sizing: content-box;
        }
      }
      .user-avator-box:hover, .bi-icon-box:hover {
        background-color: rgba(60,64,67,.08);
        transition: all .3s linear;
      }
      .user-avator-box:active, .bi-icon-box:active {
        background-color: transparent;
      }
    }
    .bi {
      font-size: 24px;
      padding: 12px;
    }
    .search-box {
      font-size: 18px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.671);
      display: flex;
      align-items: center;
      justify-content: left;
      * {
        cursor: pointer;
      }
    }
  }
  .nav-bar {
    padding-top: 8px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    .icon-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 72px;
      flex-grow: 256px;
      padding-top: 7px;
      .border {
        display: block;
        margin: 8px 0;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
      }
      .icon-box {
        border-radius: 50%;
        display: flex;
        align-items: center;
        .bi {
          font-size: 18px;
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
      }
      .icon-box:hover {
        background: rgba(0,0,0,.04);
      }
      .icon-box.focus {
        background: #e0f2f1;
        color: #00796b;
      }
    }
  }
}
</style>